import React, { useState } from 'react';
import './Navbar.css'; // You'll need to create this CSS file
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('UZ');
  const [activeDropdown, setActiveDropdown] = useState(null);

  const navItems = [
    { title: "Mahsulotlar", href: "/products" },
    // { title: "Dilerlar tarmog'i", href: "" },
    { 
      title: "Aksiyalar", 
      href: "/sales",
      
    },
    { title: "Bog'lanish", href: "/contact-us" },
    { title: "Katalogni yuklab olish", href: "https://test.cdn.samauto.uz/docs/8bd60987-cba1-4a32-bfb6-e1191099b09c_SamAuto%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B32024(UZB)_compressed.pdf" },
  ];

  const languages = [
    { code: "UZ", name: "O'zbek" },
    { code: "RU", name: "Русский" },
    { code: "EN", name: "English" },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(index);
    }
  };

  return (
    <header className="navbar">
      <div className="navbar-container">
        {/* Mobile Menu Button */}
        <button 
          className="menu-button" 
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 6H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 18H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>

        {/* Logo */}
        <Link to="/" className="logo">
          <img src="/images/logo.png" alt="SamAuto" />
        </Link>

        {/* Desktop Navigation */}
        <nav className="desktop-nav">
          <ul>
            {navItems.map((item, index) => (
              <li key={index}>
                {item.hasDropdown ? (
                  <div className="dropdown">
                    <button className="dropdown-button">
                      {item.title}
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                    <div className="dropdown-content">
                      {item.dropdownItems.map((dropdownItem, idx) => (
                        <a key={idx} href={dropdownItem.href}>
                          {dropdownItem.title}
                        </a>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link to={item.href}>{item.title}</Link>
                )}
              </li>
            ))}
          </ul>
        </nav>

        {/* Language and Search */}
        {/* <div className="nav-actions">
          <div className="language-selector">
            <button className="language-button">
              {activeLanguage}
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
            <div className="language-dropdown">
              {languages.map((lang) => (
                <button
                  key={lang.code}
                  className={activeLanguage === lang.code ? 'active' : ''}
                  onClick={() => setActiveLanguage(lang.code)}
                >
                  {lang.name}
                </button>
              ))}
            </div>
          </div>
          
          <button className="search-button" aria-label="Search">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div> */}
      </div>

      {/* Mobile Menu */}
      <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
        <div className="mobile-menu-container">
          <nav>
            {navItems.map((item, index) => (
              <div key={index} className="mobile-menu-item">
                {item.hasDropdown ? (
                  <>
                    <div 
                      className="mobile-menu-dropdown-header"
                      onClick={() => toggleDropdown(index)}
                    >
                      <Link to={item.href}>{item.title}</Link>
                      <svg 
                        className={activeDropdown === index ? 'rotate' : ''} 
                        width="16" height="16" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                    {activeDropdown === index && (
                      <div className="mobile-dropdown-content">
                        {item.dropdownItems.map((dropdownItem, idx) => (
                          <a key={idx} href={dropdownItem.href}>
                            {dropdownItem.title}
                          </a>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <Link to={item.href}>{item.title}</Link>
                )}
              </div>
            ))}
          </nav>
          
          {/* <div className="mobile-language-selector">
            <p>Til</p>
            <div className="language-buttons">
              {languages.map((lang) => (
                <button
                  key={lang.code}
                  className={activeLanguage === lang.code ? 'active' : ''}
                  onClick={() => setActiveLanguage(lang.code)}
                >
                  {lang.code}
                </button>
              ))}
            </div>
          </div> */}
        </div>
      </div>

      {/* Overlay for mobile menu */}
      {isMenuOpen && (
        <div className="menu-overlay" onClick={toggleMenu}></div>
      )}
    </header>
  );
};

export default Navbar;