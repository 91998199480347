import React, { useContext } from 'react';
import './ProductPage.css';
import Navbar from '../components/navbar/Navbar';
import { Footer } from '../components/footer/Footer';
import { Context } from '../App';
import { Link } from 'react-router-dom';

function ProductPage() {
  const { category,fetchCategoryComp} = useContext(Context);

  return (
    <div className="product-page">
      {/* Header */}
      <Navbar />

      {/* Main Content */}
      <main className="main-content">
        <div className="container">
          <h1 className="page-title">Mahsulotlar</h1>
          <div className="product-grid" data-aos="zoom-out">
            {category?.map((item, index) => (
              <div className="product-card" key={index}>
                <div className={`product-image-container ${item.bgClass}`}>
                  <img src={item.img} alt={item.title} className="product-image" />
                  <div className="image-overlay"></div>
                  <h2 className="product-title">{item.title}</h2>
                  <div className="product-action">
                    <button onClick={()=>fetchCategoryComp(item.title)} className="konfigurator-button">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="chevron-icon">
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                      <span>Konfigurator</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default ProductPage;
