import React from "react";

const GoogleMap = () => {
  return (
    <div style={{textAlign:"center"}}>
        <h1>Xaritada Joylashgan Joyimiz</h1>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3106.8394918951853!2d65.78601807534085!3d38.85903097173234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f4ea70045e3aa19%3A0x9c4889346acf44ff!2sAvto%20Saltanat!5e0!3m2!1sen!2s!4v1742913268386!5m2!1sen!2s"
        
        height="400"
        style={{ border: 0,width:"80%",margin:"auto" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
