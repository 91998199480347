import { Link, useParams } from "react-router-dom"
import { Footer } from "../components/footer/Footer"
import Navbar from "../components/navbar/Navbar"
import "./ProductDetail.css"
import { useContext } from "react"
import { Context } from "../App"


function ProductDetailPage() {
  const {selectProduct} = useContext(Context)
  const {id} = useParams()
  return (
    <div className="bus-detail-page" data-aos="zoom-out">
      {/* Header */}
     <Navbar />

      {/* Breadcrumbs */}
      <div className="breadcrumbs">
        <div className="container">
          <div className="breadcrumbs-list">
            <a href="#" className="breadcrumb-item">
              Asosiy sahifa
            </a>
            <span className="breadcrumb-separator">•</span>
            <Link to="/products" className="breadcrumb-item">
              Mahsulotlar
            </Link>
            <span className="breadcrumb-separator">•</span>
            <Link to="/" className="breadcrumb-item">
              {selectProduct.category.title}
            </Link>
            <span className="breadcrumb-separator">•</span>
            <span className="breadcrumb-item active">
              {selectProduct.title}
            </span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="main-content">
        <div className="container">
          <h1 className="page-title" style={{textTransform:'uppercase'}}>{selectProduct.title}</h1>

          <div className="product-details">
            <div className="product-description">
              <p>
                {
                  selectProduct.description
                }
                
                
              </p>
              <button  className="button-89" role="button">
              {
                  selectProduct.price
                }00,000so'm
              </button>
              
              
              <Link to={selectProduct.file} download target="_blank" className="compare-button button-86">Yuklab olish</Link>
            </div>
            <div className="product-image">
              <img src={selectProduct.image} alt="Avtobus SAZ LE60" className="bus-image" />
            </div>
          </div>

          <section className="technical-specs" data-aos="zoom-out">
            <h2 className="section-title">Texnik tasnifi</h2>

            <div className="specs-grid">
              <div className="spec-item">
                <div className="spec-value">{selectProduct.technical_specification.max_weight} kg</div>
                <div className="spec-label">Ruxsat etilgan yalpi vazn</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">{selectProduct.technical_specification.dimensions}</div>
                <div className="spec-label">Umumiy o'lchamlar (U x G x Y), mm</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">{selectProduct.technical_specification.engine_volume}</div>
                <div className="spec-label">Dvigatel modeli</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">
                  {selectProduct.technical_specification.cargo_capacity} sm<sup>3</sup>
                </div>
                <div className="spec-label">Ish hajmi</div>
              </div>


              <div className="spec-item">
                <div className="spec-value">{selectProduct.technical_specification.wheelbase} mm</div>
                <div className="spec-label">G'ildirak bazasi</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">{selectProduct.technical_specification.max_power}</div>
                <div className="spec-label">Maksimal dvigatel kuchi</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">{selectProduct.technical_specification.fuel_type}</div>
                <div className="spec-label">Yoqilg'i turi</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">4x2</div>
                <div className="spec-label">G'ildirak formulasi</div>
              </div>
            </div>
          </section>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default ProductDetailPage

