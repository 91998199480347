import React, { createContext, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, useNavigationType } from "react-router-dom";
import Home from "./pages/Home";
import ProductPage from "./pages/ProductPage";
import ProductDetailPage from "./pages/ProductDetail";
import ProductCards from "./pages/ProductCard";
import axios from "axios";
import Contact from "./pages/Contact";
import { LoadingBarContainer, useLoadingBar } from "react-top-loading-bar";
import NotFound from "./pages/NotFound";

export const Context = createContext();

function MainApp() {
  const { start, complete } = useLoadingBar();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [products, setProducts] = useState(null);
  const [news, setNews] = useState(null);
  const [category, setCategory] = useState(null);
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const navigate = useNavigate();

  async function fetchProduct() {
    let query = await axios.get("https://backend.avtosaltanat.uz/pro/api/products/");
    setProducts(query.data);
  }

  async function fetchCategory() {
    let query = await axios.get("https://backend.avtosaltanat.uz/pro/api/category/");
    setCategory(query.data);
  }

  async function fetchCategoryComp(category) {
    console.log("Tanlangan kategoriya:", category);
    const query = await axios.get("https://backend.avtosaltanat.uz/pro/api/products/");
    const filteredData = query.data.filter((c) => c.category.title === category);
    setSelectCategory(filteredData);
    navigate("/products/detail");
  }

  const fetchProductDetail = async (id) => {
    const query = await axios.get(`https://backend.avtosaltanat.uz/pro/api/products/${id}/`);
    setSelectProduct(query.data);
    navigate("/products/detail/" + id);
  };

  useEffect(() => {
    if (navigationType !== "POP") {
      start(); // Yangi sahifa yuklanayotganda boshlanadi
      setTimeout(() => {
        complete(); // 1.5 sekund kutib turib, tugatish
      }, 1500);
    }
    fetchProduct();
    fetchCategory();
  }, [location]);
  

  return (
    <Context.Provider value={{ products, category, fetchCategoryComp, selectCategory, fetchProductDetail, selectProduct }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/products/detail/" element={<ProductCards />} />
        <Route path="/products/detail/:id" element={<ProductDetailPage />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Context.Provider>
  );
}

function App() {
  return (
    <LoadingBarContainer>
      <MainApp />
    </LoadingBarContainer>
  );
}

export default App;
