"use client"

import { useState, useEffect } from "react"

// Simple arrow icons as SVG components
const ChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="m15 18-6-6 6-6" />
  </svg>
)

const ChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="m9 18 6-6-6-6" />
  </svg>
)

const slides = [
  {
    image: "https://media.drive.com.au/obj/tx_q:70,rs:auto:1920:1080:1/driveau/upload/cms/uploads/a7tgfapzsadx4m3zstqm",
    title: "Isuzu D-MAX",
    description: "The all-new powerful pickup with enhanced capabilities",
  },
  {
    image: "https://frankfurt.apollo.olxcdn.com/v1/files/4o7dky7yvfp82-UZ/image;s=1023x717",
    title: "Isuzu MU-X",
    description: "Premium SUV with exceptional comfort and performance",
  },
]

export function SliderDemo() {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)
  const totalSlides = slides.length

  const nextSlide = () => {
    if (isAnimating) return
    setIsAnimating(true)
    setCurrentSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1))
    setTimeout(() => setIsAnimating(false), 500)
  }

  const prevSlide = () => {
    if (isAnimating) return
    setIsAnimating(true)
    setCurrentSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1))
    setTimeout(() => setIsAnimating(false), 500)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide()
    }, 5000)
    return () => clearInterval(interval)
  }, [currentSlide])

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          position: "relative",
          height: "500px",
          width: "100%",
          transition: "transform 500ms ease-in-out",
          transform: `translateX(-${currentSlide * 100}%)`,
          display: "flex",
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              flexShrink: 0,
              flex: "0 0 100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                inset: 0,
                backgroundImage: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <div
              style={{
                position: "absolute",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "40px",
                color: "white",
              }}
            >
              <h2
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  marginBottom: "8px",
                }}
              >
                {slide.title}
              </h2>
              <p
                style={{
                  fontSize: "1.25rem",
                  opacity: 0.9,
                }}
              >
                {slide.description}
              </p>
              {/* <button
                style={{
                  marginTop: "16px",
                  padding: "8px 16px",
                  backgroundColor: "#2563eb",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Learn More
              </button> */}
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Arrows */}
      <button
        style={{
          position: "absolute",
          left: "16px",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(4px)",
          border: "none",
          color: "white",
          padding: "8px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={prevSlide}
      >
        <ChevronLeft />
      </button>
      <button
        style={{
          position: "absolute",
          right: "16px",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(4px)",
          border: "none",
          color: "white",
          padding: "8px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={nextSlide}
      >
        <ChevronRight />
      </button>

      {/* Indicators */}
      <div
        style={{
          position: "absolute",
          bottom: "16px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "8px",
        }}
      >
        {slides.map((_, index) => (
          <button
            key={index}
            style={{
              height: "8px",
              width: "32px",
              borderRadius: "9999px",
              backgroundColor: currentSlide === index ? "white" : "rgba(255, 255, 255, 0.4)",
              border: "none",
              cursor: "pointer",
              transition: "all 300ms",
            }}
            onClick={() => {
              setCurrentSlide(index)
            }}
          />
        ))}
      </div>
    </div>
  )
}

