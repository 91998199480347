import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.text}>Kechirasiz, bu sahifa topilmadi!</p>
      <Link to="/" style={styles.link}>Bosh sahifaga qaytish</Link>
    </div>
  );
}

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
  },
  heading: {
    fontSize: "72px",
    color: "#FF5733",
  },
  text: {
    fontSize: "18px",
    color: "#333",
  },
  link: {
    fontSize: "20px",
    textDecoration: "none",
    color: "#007BFF",
  },
};

export default NotFound;
