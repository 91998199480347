import React from 'react'
import Navbar from '../components/navbar/Navbar'
import { SliderDemo } from '../components/slider/Slider'
import { ProductsSection } from '../components/product/Product'
import { NewsSection } from '../components/news/News'
import { Footer } from '../components/footer/Footer'
import GoogleMap from '../components/Maps/Maps'

function Home() {
  return (
    <div>
        <Navbar />
        <SliderDemo />
        <div data-aos="zoom-out">
        <ProductsSection />
        </div>
        <div data-aos="zoom-out">
        <GoogleMap />
        </div>

    
        {/* <NewsSection /> */}
        <Footer />
    </div>
  )
}

export default Home