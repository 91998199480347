

import React, { useContext } from "react"
import "./ProductCard.css"
import Navbar from "../components/navbar/Navbar"
import { Footer } from "../components/footer/Footer"
import { Context } from "../App"

// Individual Product Card Component
function ProductCard({ title, imageUrl, isActive, id }) {
  const { fetchProductDetail } = useContext(Context);

  return (
    <div className={`product-card ${isActive ? "active" : ""}`}>
      <h3 className="product-title">{title || "Noma'lum mahsulot"}</h3>

      <div className="product-image-container">
        <img src={imageUrl || "/placeholder.svg"} alt={title} className="product-image" />
      </div>

      <button className="details-button" onClick={() => fetchProductDetail?.(id)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="chevron-icon"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
        Ba'tafsil
      </button>
    </div>
  );
}

// Product Category Tabs Component
function CategoryTabs({ activeTab, onTabChange }) {
  return (
    <div className="category-tabs">
      {/* Kategoriya tugmalari o‘chirib qo‘yilgan, kerak bo‘lsa qo‘shish mumkin */}
    </div>
  );
}

// Main Product Cards Container Component
function ProductCards() {
  const [activeTab, setActiveTab] = React.useState("all");
  const { selectCategory } = useContext(Context);
  console.log('Tanlangan kategoriya:', selectCategory);

  // Kategoriyaga qarab mahsulotlarni filtrlash
  const filteredProducts = selectCategory?.filter((product) =>
    activeTab === "all" || product.category === activeTab
  ) || [];

  return (
    <>
      <Navbar />
      <div data-aos="zoom-out" className="product-section" style={{ padding: 30 }}>
        <h2 className="section-title">Mahsulotlar</h2>

        <CategoryTabs activeTab={activeTab} onTabChange={setActiveTab} />

        <div className="product-grid">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <ProductCard
                key={product.id}
                id={product.id}
                title={product.title}
                imageUrl={product.image}
                isActive={false}
              />
            ))
          ) : (
            <p>Mahsulotlar topilmadi.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProductCards;
