import React from 'react'
import Navbar from '../components/navbar/Navbar'
import { Footer } from '../components/footer/Footer'

function Contact() {
  return (
    <div>
        <Navbar />
        <div data-aos="zoom-out">

                <div style={{width:"90%",objectFit:"cover",margin:"auto"}} >

                    <h2>Biz haqimizda</h2>
                    <img height={800} style={{width:"100%",borderRadius:"20px"}}  src="https://buxara-auto.uz/static/images/banner-5.webp" alt="" />


                    <h2>Qarshida Office</h2>
                   
                    <ul className='contact-list'>
                        <li>Manzil
                            <li><i className="fa-solid fa-location-dot"></i>"AVTO SALTANAT SERVIS" МЧЖ- Қашқадарё вилояти, Қарши шаҳар, Зардўзлик кўчаси, 74-уй</li>
                        </li>
                        <li>Aloqalar
                            <li><i className="fa-solid fa-phone"></i> +998 97 488 06 05
                            </li>
                        </li>
                        <li>Email
                            <li><i className="fa-solid fa-envelope"></i> info@avtosaltanat.uz</li>
                        </li>
                        <li>Ishlar
                            <li><i className="fa-solid fa-hourglass-half"></i> Dushanba - Juma 09:00-18:00</li>
                        </li>
                    </ul>

                </div>

                </div>
        <Footer />
    </div>
  )
}

export default Contact