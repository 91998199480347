import React, { useContext } from 'react';
import { Context } from '../../App';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



export function ProductsSection() {
  const {category,fetchCategoryComp} = useContext(Context)



  return (
    <section style={{ padding: '40px 0', maxWidth: '1200px', margin: '0 auto' }}>
      <h1 style={{ 
        fontSize: '36px', 
        fontWeight: 'bold', 
        textAlign: 'center', 
        marginBottom: '40px',
        color: '#222'
      }}>
        Mahsulotlar
      </h1>
      
      <div style={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: '20px', 
        justifyContent: 'center' 
      }}>
        {category?.map(category => (
          <div 
            key={category.id} 
            style={{
              width: '270px',
              height: '400px',
              borderRadius: '8px',
              overflow: 'hidden',
              position: 'relative',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              transition: 'transform 0.3s ease',
              cursor: 'pointer'
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = 'translateY(-5px)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'translateY(0)'}
          >
            <img 
              src={category.img || "/placeholder.svg"} 
              alt={category.title}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.7))'
            }}></div>
            <h3 style={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              color: 'white',
              fontSize: '24px',
              fontWeight: 'bold',
              margin: 0
            }}>
              {category.title}
            </h3>
            <div style={{
              position: 'absolute',
              bottom: '20px',
              left: '20px',
              display: 'flex',
              alignItems: 'center',
              color: 'white',
              padding: '10px 20px'
             
            }} onClick={()=>fetchCategoryComp(category.title)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
              <span style={{ marginLeft: '5px' }}>Konfigurator</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
